<template>
    <v-ons-page class="manager-user-settings" >
        <v-ons-toolbar inline="true"  >
            <div class="left">
                <v-ons-back-button @click.prevent="goBack" ></v-ons-back-button>
            </div>

            <div class="center">Индивидуальный тип цен</div>

            <div class="right">
                <v-ons-toolbar-button @click="save" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <ons-list>
            <div class="wrap" >
                <div class="col xl-1-2" >
                    <div class="list-header list-header--material" >
                        Типы клиентов/цен:
                    </div>
                </div>

                <div class="col xl-1-2" >
                    <div class="list-header list-header--material" style="text-align: right;" >
                        % наценки<br>от базового типа цен
                    </div>
                </div>
            </div>

            <ons-list-item v-for="type in priceTypes" :key="type.id" modifier="longdivider" >
                <div class="left" style="margin-right: 10px; min-width: 0;">
                    <v-ons-radio
                        :input-id="'radio-' + templateId +'-' + type.id"
                        :value="type.id"
                        v-model="priceType"
                    ></v-ons-radio>
                </div>

                <label class="center label-reset" :for="'radio-' + templateId +'-' + type.id" >
                    {{ type.name }}
                </label>
                <div class="right" >
                    <input type="number" class="without-arrow" v-model="type.markup" style="width: 50px; text-align: center;" />
                </div>
            </ons-list-item>
        </ons-list>

        <br>
        <ons-list>
            <ons-list-item modifier="longdivider" >
                <div class="center" >
                    <b>Комиссия при оплате по терминалу (%)</b>
                </div>
                <div class="right" >
                    <input type="number" class="without-arrow" v-model="terminalTax" placeholder="0" style="text-align: center; width: 50px;" />
                </div>
            </ons-list-item>
        </ons-list>

        <br>

        <div class="list-header--material" >Способ предоставления скидки клиенту:</div>

        <discount-section
            :title='discounts.get_discount.name'
            :forPriceType.sync="discounts.get_discount.forPriceType"
            :gotoPriceType.sync="discounts.get_discount.gotoPriceType"
            :isEnabled.sync="discounts.get_discount.isEnabled"
            :priceTypes="priceTypes" />

        <discount-section
            :title='discounts.order_amount_more.name'
            :forPriceType.sync="discounts.order_amount_more.forPriceType"
            :gotoPriceType.sync="discounts.order_amount_more.gotoPriceType"
            :isEnabled.sync="discounts.order_amount_more.isEnabled"
            :amount.sync="discounts.order_amount_more.amount"
            :hasAmount="true"
            :priceTypes="priceTypes" />

        <discount-section
            :title='discounts.order_amount_more_month.name'
            :forPriceType.sync="discounts.order_amount_more_month.forPriceType"
            :gotoPriceType.sync="discounts.order_amount_more_month.gotoPriceType"
            :isEnabled.sync="discounts.order_amount_more_month.isEnabled"
            :amount.sync="discounts.order_amount_more_month.amount"
            :hasAmount="true"
            :priceTypes="priceTypes" />

        <discount-section
            :title='discounts.self_ordering.name'
            :forPriceType.sync="discounts.self_ordering.forPriceType"
            :gotoPriceType.sync="discounts.self_ordering.gotoPriceType"
            :isEnabled.sync="discounts.self_ordering.isEnabled"
            :amount.sync="discounts.self_ordering.amount"
            :hasAmount="false"
            :priceTypes="priceTypes" />
    </v-ons-page>
</template>

<script>

import DiscountSection from '~/component/DiscountSection'

export default {
    data() {
        return {
            changed: false,
            loaded: false,

            templateId: null,
            userId: null,
            priceType: null,
            priceTypes: [],
            terminalTax: 0,
            discounts: {
                get_discount: {},
                order_amount_more: {},
                order_amount_more_month: {},
                self_ordering: {},
            },
        };
    },
    created() {
        this.userId = this.$route.params.user_id;
        this.templateId = this.getUniqueId();

        this.$http.get('/api/manager/users/' + this.userId + '/custom-price-type-settings').then(response => {
            this.priceType = response.data.priceType.toString();
            this.priceTypes = response.data.priceTypes.map(i => {
                i.id = i.id.toString();
                return i;
            });
            this.terminalTax = response.data.terminalTax;

            this.$nextTick(() => {
                response.data.discounts.forEach(i => {
                    this.discounts[i.alias] = {
                        id: i.id,
                        name: i.name,
                        forPriceType: i.for_price_type.toString(),
                        gotoPriceType: i.goto_price_type.toString(),
                        isEnabled: !!i.is_enabled,
                        amount: i.amount ? i.amount.toString() : null,
                    };
                });

                this.$nextTick(() => {
                    this.loaded = true;
                });
            });
        }, () => {
            this.$ons.notification.toast({
                message: 'Произошла ошибка.',
                buttonLabel: 'OK',
            });
        });
    },
    methods: {
        goBack() {
            this.$bus.$emit('update-custom-price-type');

            if (!this.changed) {
                this.$router.goRouteBack();
                return;
            }


            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.goRouteBack();
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
        handlerChange() {
            if (!this.loaded) {
                return;
            }
            this.changed = true;
        },
        save() {
            let data = {
                priceType: this.priceType,
                priceTypes: this.priceTypes,
                discounts: this.discounts,
                terminalTax: this.terminalTax,
            };

            this.$http.post('/api/manager/users/' + this.userId + '/custom-price-type-settings', data).then(() => {
                this.$bus.$emit('update-custom-price-type');
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Настройки сохранены',
                    buttonLabel: 'OK',
                });
                this.$router.go(-1);
            }, (response) => {
                let message = 'Произошла ошибка'

                if (response.data && response.data.message) {
                    message = response.data.message;
                }

                this.$ons.notification.toast({
                    timeout: 4000,
                    message: message,
                    buttonLabel: 'OK',
                });
            });
        },
    },
    components: {
        DiscountSection,
    },
    watch: {
        terminalTax: {
            handler(e) { this.handlerChange(e) },
            deep: true,
        },
        priceType: {
            handler(e) { this.handlerChange(e) },
            deep: true,
        },
        priceTypes: {
            handler(e) { this.handlerChange(e) },
            deep: true,
        },
        discounts: {
            handler(e) { this.handlerChange(e) },
            deep: true,
        },
    },
};
</script>