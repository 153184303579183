<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button @click.prevent="goBack" ></v-ons-back-button>
            </div>

            <div class="center">
                <div v-if="order.id">
                    <span>Заказ №{{ order.id }}</span>
                    <span v-if="order.status_id === 'draft' && order.was_canceled"> (отменён)</span>
                </div>
            </div>

            <div class="right" v-show="!loading" >
                <v-ons-toolbar-button @click="print">
                    <v-ons-icon icon="md-print"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button
                    @click="sendConfirmEmail"
                    v-show="!$user.isConfirmEmailSendingEnabled && order.status_id === 'confirmed'"
                    title="Отправить подтверждение заказа">
                    <v-ons-icon icon="md-assignment-return" :style="{color: order.confirm_sended_at ? '#ffbb3d' : 'white'}"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button v-show="order.status_id === 'manager_editing'" @click="endEditConfirmedOrder">
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button v-show="['sended', 'manager_draft'].includes(order.status_id)" @click="preConfirmOrder">
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>

                <toolbar-popover-menu popover-class="stick-right" >
                    <toolbar-popover-menu-item
                        text="Подтвердить"
                        icon="md-check"
                        v-show="order && order.status_id === 'sended'"
                        @click="preConfirmOrder()" />

                    <toolbar-popover-menu-item
                        text="Печать"
                        icon="md-print"
                        v-show="order"
                        @click="print()" />

                    <toolbar-popover-menu-item
                        text="Отправить счет"
                        :icon="order.is_invoice_sended ? 'md-assignment-check' : 'md-assignment-o'"
                        v-if="order"
                        @click="sendInvoiceForPayment()" />

                    <toolbar-popover-menu-item
                        text="Редактировать"
                        icon="md-edit"
                        v-if="order && order.status_id === 'confirmed'"
                        @click="startEditConfirmedOrder" />

                    <toolbar-popover-menu-item
                        text="Отправить подтверждение"
                        icon="md-assignment-return"
                        v-show="!$user.isConfirmEmailSendingEnabled && order.status_id === 'confirmed'"
                        v-if="order"
                        @click="sendConfirmEmail()" />

                    <toolbar-popover-menu-item
                        text="Объединить"
                        icon="md-format-valign-center"
                        v-show="order && (order.status_id === 'sended' || order.status_id === 'confirmed')"
                        @click="$router.push($router.currentRoute.path + '/merge')" />

                    <toolbar-popover-menu-item
                        text="Отменить"
                        icon="md-block"
                        v-show="order && (order.status_id === 'sended' || order.status_id === 'confirmed')"
                        @click="cancelOrder()" />

                    <toolbar-popover-menu-item
                        text="Изменить тип цен"
                        icon="md-money"
                        v-show="order && (order.status_id === 'sended' || order.status_id === 'manager_editing')"
                        @click="showPriceTypesDialog()" />

                    <toolbar-popover-menu-item
                        text="Профиль клиента"
                        icon="md-account"
                        v-show="order"
                        @click="$router.push('/manager/users/' + order.user_id)" />
                </toolbar-popover-menu>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <div class="order-view-page" :style="{padding: '15px 5px', 'padding-bottom': paddingBottom}"  >
                <div class="form-group" >
                    Email: <a :href="'mailto:' + user.email" >{{ user.email }}</a>
                </div>

                <div class="form-group">
                    Телефон:
                    <a v-if="user.phone" :href="'tel:' + user.phone" >{{ user.phone }}</a>
                    <span v-else >не указан</span>
                </div>

                <div class="form-group" >
                    <div class="control-label" >
                        Поставщик
                    </div>

                    <div class="form-control" >
                        <div v-if="canEditOrder" class="select-material select--material select" >
                            <div v-if="providerEntities.length === 0" style="color: #898989;" >
                                Нет ни одного юр. лица
                            </div>

                            <select v-else :title="titleChoosedEntity" class="select-input select-input--material" v-model="order.provider_entity_id" >
                                <option v-if="!order.provider_entity_id" :value="null" disabled >
                                    Не выбрано
                                </option>

                                <option v-for="entity in providerEntities" :value="entity.id" :key="entity.id" >
                                    {{ entity.alias }}
                                </option>
                            </select>
                        </div>
                        <div v-else >
                            <span v-if="order.providerEntity" >{{ order.providerEntity.alias }}</span>
                            <span v-else >нет данных</span>
                        </div>
                    </div>
                </div>

                <div class="form-group" >
                    <div class="control-label" >
                        Покупатель
                    </div>

                    <div class="form-control" >
                        <div v-if="canEditOrder" class="select-material select--material select" >
                            <div v-if="entities.length === 0" style="color: #898989;" >
                                Нет ни одного юр. лица
                            </div>

                            <select v-else :title="titleChoosedEntity" class="select-input select-input--material" v-model="order.entity_id" >
                                <option v-if="!order.entity_id" :value="null" disabled >
                                    Не выбрано
                                </option>

                                <option v-for="entity in entities" :value="entity.id" :key="entity.id" >
                                    {{ entity.alias }}
                                </option>
                            </select>
                        </div>
                        <div v-else >
                            <span v-if="order.entity && order.entity.alias" >{{ order.entity.alias }}</span>
                            <span v-else >нет данных</span>
                        </div>
                    </div>
                </div>

                <div class="form-group" v-show="order.shippingMethod === 'delivery'" >
                    <div class="control-label" >
                        Адрес доставки
                    </div>

                    <div class="form-control" >
                        <div v-if="canEditOrder" class="select-material select--material select" >
                            <div v-if="branches.length === 0" style="color: #898989;" >
                                Нет ни одного адреса доставки
                            </div>

                            <select v-else :title="titleChoosedBranch" class="select-input select-input--material" v-model="order.branch_id" >
                                <option v-if="!order.branch_id" :value="null" disabled >
                                    Не выбрано
                                </option>

                                <option v-for="branch in branches" :value="branch.id" :key="branch.id" >
                                    {{ branch.stringAddressWithName }}
                                </option>
                            </select>
                        </div>
                        <span v-else >
                            {{ order.branch ? order.branch.stringAddressWithName : 'не указан' }}
                        </span>
                    </div>

                    <div v-show="order.branch && !order.branch.route" style="border: 1px solid #ff8d00; padding: 2px;" >
                        <div class="wrap" >
                            <div class="col xl-1-2" >
                                <b style="font-size: 14px;" >Маршрут не привязан</b>
                            </div>

                                <div class="col xl-1-2 xl-right" v-show="routes.length > 0" >
                                <v-ons-button @click="linkRoute(order.branch)" >Привязать</v-ons-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group" >
                    <div class="control-label" >
                        Способ оплаты
                    </div>

                    <div class="form-control" >
                        <div v-if="canEditOrder" class="select-material select--material select" >
                            <select class="select-input select-input--material" v-model="order.paymentMethod" >
                                <option v-for="method in paymentMethods" :value="method.id" :key="method.id" >
                                    {{ method.name }}
                                </option>
                            </select>
                        </div>
                        <span v-else >
                            {{ order.paymentMethodLabel }}
                        </span>
                    </div>
                </div>

                <div class="form-group" v-show="order.shippingMethod === 'delivery' && [1, 8].includes(order.paymentMethod)">
                    Сумма:
                    <input type="number" v-model="order.invoice_amount" placeholder="по накладной" />
                </div>

                <div class="form-group" >
                    <div class="control-label" >
                        Способ отгрузки
                    </div>

                    <div class="form-control" >
                        <div v-if="canEditOrder" class="select-material select--material select" >
                            <select class="select-input select-input--material" v-model="order.shippingMethod" >
                                <option v-for="method in shippingMethods" :value="method.id" :key="method.id" >
                                    {{ method.name }}
                                </option>
                            </select>
                        </div>
                        <span v-else >
                            {{ order.shippingMethodLabel }}
                        </span>
                    </div>
                </div>

                <div class="form-group" v-if="canEditOrder" >
                    Дата отгрузки:
                    <v-input-datepicker v-model="order.shipping_date" style="display: inline-block; width: 200px;" />
                </div>
                <div class="form-group" v-else >Дата отгрузки: {{ order.shippingDate }}</div>

                <p
                    v-if="order.manager_comment"
                    style="border: 1px solid #ff8d00; padding: 2px; cursor:pointer;"
                    title="Скопировать комментарий"
                    @click="copyToClipboard(order.manager_comment)"
                >
                    <b>Комментарий менеджера: {{ order.manager_comment }}</b>
                </p>

                <p
                    v-if="order.comment"
                    style="border: 1px solid #ff8d00; padding: 2px; cursor:pointer;"
                    title="Скопировать комментарий"
                    @click="copyToClipboard(order.comment)"
                >
                    <b>Комментарий: {{ order.comment }}</b>
                </p>

                <div class="form-group" >
                    Номер накладной:
                    <input
                        :disabled="!['sended', 'manager_editing'].includes(order.status_id)"
                        v-model="order.invoice_number"
                    />
                </div>

                <br>

                <table class="table" style="position: relative" >
                    <thead>
                        <tr>
                            <th></th>
                            <th>Наименование</th>
                            <th>Кол-во</th>
                            <th>Цена <span v-if="order.hasDiscount">(со скидкой)</span></th>
                            <th v-show="isShowColAction" ></th>
                        </tr>
                    </thead>

                    <v-tbody
                        :order="order"
                        :isShowColAction="isShowColAction"
                        :editable="editable"
                        :units="units"
                        @startEdit="startEdit"
                        @cancel="cancelEdit"
                        @remove="removePosition"
                        @save="savePosition"
                        @insertPosition="insertPosition"
                        @chooseAdded="onChooseAdded"
                        />
                </table>

                <div class="total-order-wrap">
                    <div v-if="isShowShippingRow" class="wrap xl-gutter-8" style="text-align: right;">
                        <div class="col">
                            <v-ons-checkbox
                                input-id="isCostForShippingIncludedLabel"
                                v-model="isCostForShippingIncluded"
                                :disabled="!canEditOrder"
                            />
                        </div>
                        <div class="col">
                            <div>
                                <label class="label-reset" for="isCostForShippingIncludedLabel">Доставка</label>
                            </div>
                        </div>
                        <div class="col">
                            <div>
                                <label class="label-reset" for="isCostForShippingIncludedLabel">{{ costForShipping }} руб.</label>
                            </div>
                        </div>
                    </div>

                    <div class="wrap total-order">
                        <div class="col col-label">
                            <div>Сумма</div>
                        </div>
                        <div class="col col-total-price">
                            <div class="total-price-wrap">{{ order.totalCost }} руб.</div>
                        </div>
                    </div>

                    <div v-show="order.hasDiscount" class="wrap total-order">
                        <div class="col col-label">
                            <div>Сумма со скидкой</div>
                        </div>
                        <div class="col col-total-price">
                            <div class="total-price-wrap">{{ order.totalCostWithDiscount }} руб.</div>
                        </div>
                    </div>
                </div>

                <hr>

                <p>Создан: {{ order.createdAt | moment('L LT') }}</p>

                <p v-if="order.sended_at" >Отправлен: {{ order.sended_at | moment('L LT') }}</p>
                <p v-else >Отправлен: не отправлен</p>

                <p v-if="order.sent_to_onec_at" >Выгружен в 1С: {{ order.sent_to_onec_at | moment('L LT') }} (номер: {{ order.onec_id }})</p>
                <p v-else >Выгружен в 1С: не выгружен</p>

                <p v-if="order.worker" >Ответственный: <a :href="`/manager/users/${order.worker.id}`" >{{ order.worker.email }}</a></p>
            </div>
        </div>

        <cancel-order-dialog ref="cancelDialog" />
        <confirm-order-dialog ref="confirmDialog" />
        <end-edit-dialog ref="endEditDialog" />

        <invoice-for-payment-dialog
            ref="invoiceForPaymentDialog"
            @change="$emit('invoiceForPaymentDialogChange', $event)"
            @sended="order.is_invoice_sended = true"
            :orderId="order.id"
            :defaultEntity="order.entity ? order.entity.default_provider_entity : null"
            :entities="providerEntities"
        ></invoice-for-payment-dialog>

        <v-list-dialog ref="linkRouteDialog" :items="routes" />

        <v-price-types-dialog ref="priceTypesDialog" showNotySendAlert="true" />

        <v-ons-fab
            :style="order.is_marked_to_send ? 'background: #ffbb3d' : ''"
            position="bottom right"
            title="Отправить заказ в 1С"
            @click="markToSend"
        >
            <span v-show="!marking" >1С</span>
            <v-ons-icon v-show="marking" size="30px" spin icon="md-spinner"></v-ons-icon>
        </v-ons-fab>

        <!--
        <v-ons-fab
            v-show="$user.id === 1566 || $user.id === 1"
            position="bottom right"
            title="Отправить заказ в 1С"
            @click="sendOrderToOdinc"
        >
            1С
        </v-ons-fab>
        -->

    </v-ons-page>
</template>

<script>

import CancelOrderDialog from './CancelOrderDialog'
import ConfirmOrderDialog from './ConfirmOrderDialog'
import EndEditDialog from './EndEditDialog'
import PositionView from './PositionView'
import PositionUpdate from './PositionUpdate'
import PositionInsert from './PositionInsert'
import InvoiceForPaymentDialog from './InvoiceForPaymentDialog'
import InputDatepicker from '~/component/InputDatepicker'
import ListDialog from '~/component/ListDialog'
import PriceTypesDialog from './PriceTypesDialog'
import $ from "jquery"
import copy from 'copy-text-to-clipboard'


import {from, merge, of} from 'rxjs'
import {catchError, debounceTime, distinctUntilChanged, filter, map, mergeMap, pluck, tap} from 'rxjs/operators'

export default {
    props: [
        'orderProp',
    ],
    data() {
        return {
            loading: true,
            saving: false,
            loaded: false,
            marking: false,

            userId: null,
            orderId: null,
            order: {},
            isCostForShippingIncluded: false,
            user: {},
            editable: [],
            paymentMethods: [],
            shippingMethods: [],
            branches: [],
            entities: [],
            routes: [],
            units: [],
            providerEntities: [],

            paddingBottom: 0,
            countQueries: 0,
        }
    },
    socket() {
        if (!this.$options.namespace) {
            this.$options.namespace = "/orders/" + this.$route.params.order_id;
        }

        return [
            {
                namespace: this.$options.namespace,
                events: {
                    updated(data) {
                        this.$bus.$emit('order-update', data.id);
                        this.load();
                    },
                    canceled(data) {
                        if (data.id === this.order.id) {
                            this.load();
                        }
                    },
                    toggleMarkToSend(data) {
                        this.order.is_marked_to_send = data.is_marked_to_send
                    },
                },
            }
        ]
    },
    created() {
        this.order = this.orderProp;
        this.userId = this.order.user_id;
        this.orderId = this.order.id;

        let requests = [
            this.$http.get('/api/manager/users/' + this.userId),
            this.$http.get('/api/payment-methods'),
            this.$http.get('/api/shipping-methods'),
            this.$http.get('/api/manager/users/' + this.userId + '/branches'),
            this.$http.get('/api/manager/users/' + this.userId + '/entities'),
            this.$http.get('/api/manager/users/' + this.userId + '/routes'),
            this.$http.get('/api/settings/entities'),
            this.$http.get('/api/product/get-units'),
        ];


        Promise.all(requests).then(responses => {
            this.user = responses[0].data;
            this.paymentMethods = responses[1].data;
            this.shippingMethods = responses[2].data;
            this.branches = responses[3].data;
            this.entities = responses[4].data;
            this.routes = responses[5].data;
            this.providerEntities = responses[6].data;
            this.units = [
                {id: '', name: '-'},
                ...responses[7].data.map(unit => {
                    return {
                        id: unit.name,
                        name: unit.name,
                    }
                })
            ]

            this.isCostForShippingIncluded = this.order.cost_for_shipping > 0;

            this.$nextTick(() => {
                this.calcPaddingBottom();
                this.loaded = true;
                this.loading = false;
            });
        }, () => {
            this.loading = false;

            this.$ons.notification.toast({
                message: 'Произошла ошибка.',
                buttonLabel: 'OK'
            });
        });

        this.$bus.$on('order-update', this.load);
        this.$on('hook:beforeDestroy', this.save);

        // issue #785: Пропадает поле подбора, если скрыть клавиатуру, поскролить выпадающий список, а потом вернуться к вводу текста.
        // this.$window.$on('resize', this.calcPaddingBottom);

        this.$ons.ready(() => {
            this.$ons.disableDeviceBackButtonHandler();
        });
    },
    beforeDestroy() {
        this.$ons.enableDeviceBackButtonHandler();
    },
    subscriptions () {
        const invoiceNumberWatch$ = this.$watchAsObservable('order.invoice_number').pipe(
            pluck('newValue'),
            filter(() => this.loaded),
            map(q => q.trim()),
            distinctUntilChanged(),
            debounceTime(500),
        )

        const invoiceAmountWatch$ = this.$watchAsObservable('order.invoice_amount').pipe(
            pluck('newValue'),
            filter(() => this.loaded),
            map(q => q.trim()),
            distinctUntilChanged(),
            debounceTime(500),
        )

        const paymentMethodWatch$ = this.$watchAsObservable('order.paymentMethod').pipe(
            pluck('newValue'),
            filter(() => this.loaded),
            distinctUntilChanged(),
            debounceTime(500),
        )

        const shippingMethodWatch$ = this.$watchAsObservable('order.shippingMethod').pipe(
            pluck('newValue'),
            filter(() => this.loaded),
            distinctUntilChanged(),
            debounceTime(500),
        )

        const branchWatch$ = this.$watchAsObservable('order.branch_id').pipe(
            pluck('newValue'),
            filter(() => this.loaded),
            distinctUntilChanged(),
            debounceTime(500),
        )

        const entityWatch$ = this.$watchAsObservable('order.entity_id').pipe(
            pluck('newValue'),
            filter(() => this.loaded),
            distinctUntilChanged(),
            debounceTime(500),
        )

        const providerEntityWatch$ = this.$watchAsObservable('order.provider_entity_id').pipe(
            pluck('newValue'),
            filter(() => this.loaded),
            distinctUntilChanged(),
            debounceTime(500),
        )

        const shippingDateWatch$ = this.$watchAsObservable('order.shipping_date').pipe(
            pluck('newValue'),
            filter(() => this.loaded),
            distinctUntilChanged(),
            debounceTime(500),
        )

        const beforeDestroyWatch$ = this.$eventToObservable('hook:beforeDestroy')

        return {
            results: merge(
                paymentMethodWatch$,
                shippingMethodWatch$,
                branchWatch$,
                entityWatch$,
                providerEntityWatch$,
                shippingDateWatch$,
                invoiceNumberWatch$,
                invoiceAmountWatch$,
                beforeDestroyWatch$
            ).pipe(
                tap(() => this.countQueries++),
                filter(() => this.order.status_id !== 'draft'),
                mergeMap(() => {
                    return from(this.save()).pipe(
                        catchError(response => {
                            this.loading = false;

                            if (response.status === 403) {
                                this.$ons.notification.toast({
                                    timeout: 4000,
                                    message: response.data.message,
                                    buttonLabel: 'OK'
                                });
                                return of(response.data.order);
                            }

                            this.$ons.notification.toast({
                                timeout: 4000,
                                message: 'Произошла ошибка при сохранении заказа.',
                                buttonLabel: 'OK'
                            });

                            return of(null);
                        }),
                        map(response => {
                            if (!response) {
                                return null;
                            }

                            if ('data' in response) {
                                return response.data.order;
                            } else {
                                return response;
                            }
                        }),
                    )
                }),
                tap(order => {
                    if (order === null) {
                        return;
                    }

                    this.countQueries--;

                    if (this.countQueries === 0) {
                        this.$set(this, 'order', order);
                        this.isCostForShippingIncluded = this.order.cost_for_shipping > 0;
                    }
                }),
            ),
            invoiceForPaymentDialogChange: this.$eventToObservable('invoiceForPaymentDialogChange').pipe(
                debounceTime(500),
                map(event => event.msg),
                tap(params => {
                    this.order.invoice_form_params = params;
                    this.$http.post('/api/manager/order/invoice-form-params', {params: params}, {params: {id: this.order.id}});
                })
            ),
            isCostForShippingIncludedChange: this.$watchAsObservable('isCostForShippingIncluded').pipe(
                pluck('newValue'),
                filter(() => this.loaded),
                distinctUntilChanged(),
                debounceTime(500),
                tap(() => {
                    let id = this.order.id;
                    this.$http.post('/api/manager/order/toggle-cost-for-shipping-include', {id: id}).then(() => {
                        this.$bus.$emit('order-update', id);
                    }, response => {
                        this.$bus.$emit('order-update', id);
                        this.$ons.notification.toast({
                            message: response.data.message,
                            buttonLabel: 'OK'
                        });
                    });
                })
            ),
        }
    },
    destroyed() {
        this.$bus.$off('order-update', this.load);

        // issue #785: Пропадает поле подбора, если скрыть клавиатуру, поскролить выпадающий список, а потом вернуться к вводу текста.
        // this.$window.$off('resize', this.calcPaddingBottom);
    },
    methods: {
        startEditConfirmedOrder() {
            this.$http.post('/api/manager/order/start-edit-confirmed', {id: this.order.id}).then(response => {
                this.order = response.data;
            }, () => {
                this.$ons.notification.toast({
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
        startEdit(pos_id) {
            this.editable.push(pos_id);

            this.$nextTick(() => {
                if (!this.$window.isDesktop) {
                    this.scrollPage($('#position-' + pos_id));
                }
                $('#position-count-' + pos_id).focus();
            });
        },
        cancelEdit(id) {
            this.editable = this.editable.filter(i => i != id)
        },
        removePosition(id) {
            this.$noty.confirm('Удалить?').then(response => {
                if (!response) {
                    return;
                }

                this.editable = this.editable.filter(i => i != id)

                this.$http.delete(`/api/manager/orders/${this.orderId}/positions/${id}`).then(() => {
                    this.$bus.$emit('order-update', this.order.id);
                }, response => {
                    this.load();
                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: response.data.message,
                        buttonLabel: 'OK',
                    });
                });
            });
        },
        savePosition(position) {
            if (position.count === 0) {
                this.removePosition(position.pos_id);
                return;
            }

            this.editable = this.editable.filter(i => i != position.pos_id);

            this.$http.post(
                `/api/manager/orders/${this.orderId}/positions/${position.pos_id}`,
                {position: position}
            ).then(() => {
                this.$bus.$emit('order-update', this.$route.params.order_id);
            }, response => {
                this.load();
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: response.data.message,
                    buttonLabel: 'OK',
                });
            });
        },
        insertPosition(position) {
            this.$http.put(
                `/api/manager/orders/${this.orderId}/positions`,
                {positions: [position]}
            ).then(() => {
                this.$bus.$emit('order-update', this.$route.params.order_id);
            }, response => {
                this.load();
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: response.data.message,
                    buttonLabel: 'OK',
                });
            });
        },
        save() {
            if (!this.canEditOrder) {
                return Promise.resolve();
            }

            if (this.order.status_id === 'confirmed') {
                return Promise.resolve();
            }

            let order = {
                invoice_number: this.order.invoice_number,
                invoice_amount: this.order.invoice_amount,
                payment_method: this.order.paymentMethod,
                shipping_method: this.order.shippingMethod,
                branch_id: this.order.branch_id,
                entity_id: this.order.entity_id,
                provider_entity_id: this.order.provider_entity_id,
                shipping_date: this.order.shipping_date,
            }

            this.saving = true;

            return this.$http.post(`/api/manager/orders/${this.orderId}`, {order: order}).then(response => {
                this.saving = false;
                return Promise.resolve(response);
            }, () => {});
        },
        load() {
            this.$http.get(`/api/manager/orders/${this.orderId}`).then(response => {
                this.order = response.data.order;
            }, () => {
                this.$ons.notification.toast({
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
        print() {
            let win = window.open(`/api/manager/order/pdf?id=${this.order.id}&access-token=${this.$user.token}`, '_blank');
            win.focus();
        },
        cancelOrder() {
            this.$refs.cancelDialog.confirm().then(response => {
                if (!response.response) {
                    return;
                }

                this.$http.post(
                    '/api/manager/order/cancel',
                    {id: this.order.id, withNoty: response.withNoty, reason: response.reason}
                ).then(() => {
                    this.$bus.$emit('order-update', this.order.id);
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: `Заказ №${this.order.id} отменен`,
                    });
                    this.$router.push('/manager/order');
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                })
            });
        },
        endEditConfirmedOrder() {
            if (this.hasChange) {
                this.$ons.notification.toast('У вас есть несохраненные изменения', {
                    title: '',
                    buttonLabels: ['Ok']
                });
                return;
            }

            this.$refs.endEditDialog.confirm()
                .then(response => {
                    this.loading = true;
                    return Promise.resolve(response);
                })
                .then(response => {
                    return new Promise(resolve => {
                        this.save().then(() => resolve(response));
                    });
                })
                .then(response => {
                    return this.$http.post(
                        '/api/manager/order/end-edit-confirmed',
                        {
                            id: this.order.id,
                            withNoty: response.withNoty,
                            reason: response.reason,
                        }
                    )
                })
                .then(() => {
                    let id = this.order.id;

                    this.$bus.$emit('order-update', id);
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: `Заказ №${id} сохранён`,
                    });
                    this.loading = false;

                    return new Promise(resolve => resolve())
                })
                .catch((response) => {
                    if (response === 'break') {
                        return;
                    }

                    this.loading = false;

                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                })
        },
        preConfirmOrder() {
            if (this.hasChange) {
                this.$ons.notification.toast('У вас есть несохраненные изменения', {
                    title: '',
                    buttonLabels: ['Ok']
                });
                return;
            }

            let showInvoiceNumber = this.order.shippingMethod === 'pickup' && !this.order.invoice_number;
            let showChoiceWithNoty = this.order.status_id === 'manager_draft';

            this.$refs.confirmDialog.show(showInvoiceNumber, showChoiceWithNoty).then(response => {
                if (response === -1) {
                    return;
                }

                if (showInvoiceNumber) {
                    this.order.invoice_number = response.invoice_number;
                }

                this.loading = true;

                this.save()
                    .then(() => this.confirmOrder(response.withNoty))
                    .then(() => {
                        this.loading = false;
                        return new Promise(resolve => resolve())
                    })
                    .catch(response => {
                        this.loading = false;

                        if (response.status === 403) {
                            this.$ons.notification.toast({
                                timeout: 4000,
                                message: response.data.message,
                                buttonLabel: 'OK'
                            });
                            this.$set(this, 'order', response.data.order);
                        } else {
                            this.$ons.notification.toast({
                                timeout: 4000,
                                message: 'Произошла ошибка.',
                                buttonLabel: 'OK'
                            });
                        }
                    })
            });
        },
        confirmOrder(withNoty) {
            return this.$http.post(
                '/api/manager/order/confirm',
                {id: this.order.id, withNoty: withNoty}
            ).then(() => {
                this.$bus.$emit('order-update', this.order.id);
                this.$bus.$emit('orders-update');
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: `Заказ №${this.order.id} подтверждён`,
                });
            }, (response) => {
                if (response.status === 403) {
                    this.load();
                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: response.data,
                        buttonLabel: 'OK',
                    });

                    return;
                }

                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            })
        },
        goBack() {
            if (!this.hasChange) {
                this.$router.goRouteBack();
                return;
            }

            this.$ons.notification.toast('У вас есть несохраненные изменения', {
                title: '',
                buttonLabels: ['Ok']
            });
        },
        sendInvoiceForPayment() {
            this.$refs.invoiceForPaymentDialog.show(this.order);
        },
        sendConfirmEmail() {
            this.$ons.notification.confirm('Отправить подтверждение заказа?', {
                title: '',
                buttonLabels: ['Отправить', 'Отмена']
            }).then(response => {
                if (response === 1) {
                    return;
                }

                this.$http.post(
                    '/api/manager/order/send-confirm-email',
                    {id: this.order.id}
                ).then(() => {
                    this.$bus.$emit('order-update', this.order.id);
                    this.$ons.notification.toast({
                        timeout: 2000,
                        message: 'Подтверждение заказа отправлено',
                        buttonLabel: 'OK'
                    });
                }, () => {
                    this.$ons.notification.toast({
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                });
            });
        },
        linkRoute(branch) {
            if (!branch || this.saving || this.loading || this.order.branch.route) {
                return;
            }

            this.$refs.linkRouteDialog.show({title: branch.alias}).then(route => {
                this.$http.post(
                    `/api/manager/users/${this.user.id}/branches/${branch.id}/link-route`,
                    {route: route}
                ).then(() => {
                    this.load();
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                });
            });
        },
        showPriceTypesDialog() {
            let priceType = this.order.priceType;

            if (priceType) {
                priceType = priceType.toString();
            }

            let url = `/api/manager/orders/${this.orderId}/price-type-settings`;

            this.$http.get(url)
                .then(response => {

                    let params = {
                        selected: priceType,
                        items: response.data.priceTypes,
                        discount: response.data.discount,
                        order: this.order,
                    }

                    return this.$refs.priceTypesDialog.show(params);
                })
                .then(response => {
                    this.$http.post(url, {settings: response}).then(() => {
                        this.load();

                        this.$ons.notification.toast({
                            timeout: 1000,
                            message: 'Тип цен изменен',
                            buttonLabel: 'OK'
                        });
                    }, () => {
                        this.$ons.notification.toast({
                            message: 'Произошла ошибка.',
                            buttonLabel: 'OK'
                        });
                    });
                });
        },
        onChooseAdded(proposal) {
            if ('activeElement' in document) {
                document.activeElement.blur();
            }

            this.$nextTick(() => {
                this.scrollPage($('#position-' + proposal.pos_id));
            })
        },
        scrollPage(target) {
            let $target = $(target);
            let offset = $target.position().top;
            $target = $target.offsetParent();

            while (!$target.hasClass('page__content')) {
                offset += $target.position().top;
                $target = $target.offsetParent();
            }

            offset += $target.scrollTop();

            let scrollTop = $target.scrollTop();

            if (offset < 1) {
                return;
            }

            if (scrollTop - 5 < offset && scrollTop + 5 > offset) {
                return;
            }

            $target.animate({
                scrollTop: offset
            }, 300);
        },
        calcPaddingBottom() {
            let offset = this.order.worker ? 215 : 190;
            this.paddingBottom = ($(window).height() - offset) +'px';
        },
        markToSend() {
            if (this.marking) {
                return;
            }

            this.marking = true;

            this.$http.post(`/api/manager/orders/${this.order.id}/toggle-mark-to-send`, {marked: !this.order.is_marked_to_send}).then(() => {
                this.$bus.$emit('order-update', this.order.id);
                this.marking = false;
            }, response => {
                this.marking = false;

                this.$ons.notification.alert({
                    title: 'Ошибка',
                    message: response.data.message,
                });
            });
        },
        copyToClipboard(text) {
            copy(text)

            this.$ons.notification.toast({
                message: 'Комментарий скопирован',
                timeout: 2000,
                buttonLabel: 'OK'
            });
        }
        /*
        sendOrderToOdinc() {
            if (this.$user.id !== 1566 && this.$user.id !== 1) {
                return
            }

            this.$http.post('/api/manager/order/send-to-odinc', {id: this.order.id}).then(response => {
                this.$bus.$emit('order-update', this.order.id);
                this.$ons.notification.toast({
                    message: 'Заказ отправлен в 1С. Номер ' +  response.data.items[0].number,
                    buttonLabel: 'OK'
                });
            }, response => {
                this.$ons.notification.toast({
                    message: response.data.message,
                    buttonLabel: 'OK'
                });
            });
        },
         */
    },
    computed: {
        isShowColAction() {
            if (this.order && this.order.status_id === 'manager_draft') {
                return true;
            }

            if (!this.order || !this.order.positions) {
                return false;
            }

            return this.canEditOrder;
        },
        isEditing(position) {
            return this.editable.indexOf(position.pos_id) !== -1;
        },
        hasChange() {
            if (this.editable.length > 0) {
                return true;
            }
        },
        costForShipping() {
            return this.order.cost_for_shipping ? this.order.cost_for_shipping : this.order.branch.route.delivery_cost;
        },
        isShowShippingRow() {
            let order = this.order;

            if (!order || order.shippingMethod !== 'delivery' || !order.branch || !order.branch.route) {
                return false;
            }
            let route = order.branch.route;

            return !(!route.free_delivery_from || !route.delivery_cost);
        },
        titleChoosedEntity() {
            if (!this.order || !this.order.entity_id) {
                return;
            }

            let entity = this.entities.find(i => i.id == this.order.entity_id);

            if (!entity) {
                return;
            }

            return entity.alias;
        },
        titleChoosedBranch() {
            if (!this.order || !this.order.branch_id) {
                return;
            }

            let branch = this.branches.find(i => i.id == this.order.branch_id);

            if (!branch) {
                return;
            }

            return branch.alias;
        },
        canEditOrder() {
            return ['sended', 'manager_editing', 'manager_draft'].includes(this.order.status_id);
        },
    },
    components: {
        'cancel-order-dialog': CancelOrderDialog,
        'confirm-order-dialog': ConfirmOrderDialog,
        'end-edit-dialog': EndEditDialog,
        'invoice-for-payment-dialog': InvoiceForPaymentDialog,
        'position': {
            functional: true,
            render(createElement, context) {
                let editable = context.data.attrs.editable;
                let position = context.data.attrs.position;

                if (editable.indexOf(position.pos_id) !== -1) {
                    return createElement(PositionUpdate, context.data, context.children)
                }

                return createElement(PositionView, context.data, context.children)
            },
        },
        'v-tbody': {
            props: [
                'isShowColAction',
                'editable',
                'order',
                'units',
            ],
            render(h) {
                let colspan = 3;
                let order = this.order;

                if (order.hasDiscount) {
                    colspan++;
                }

                if (this.isShowColAction) {
                    colspan++;
                }

                if (!order || !order.positions) {
                    let innerHTML = '<tr><td style="padding: 10px 5px 10px 23px" colspan="' + colspan + '" >Позиций нет</td></tr>';
                    return h(
                        'tbody',
                        {domProps: {innerHTML: innerHTML}}
                    );
                }

                let unwaitingPositions = order.positions.filter(i => !i.is_available);
                let waitingPositions = order.positions.filter(i => i.is_available);

                let position = null;
                let elements = [];
                let element = null;
                let elementData = {};
                let index = 1;

                let addedPositions = [];

                for(position of unwaitingPositions) {
                    addedPositions.push({
                        id: position.prd_id,
                        index: index++,
                        pos_id: position.pos_id,
                    });
                }

                for(position of waitingPositions) {
                    addedPositions.push({
                        id: position.prd_id,
                        index: index++,
                        pos_id: position.pos_id,
                    });
                }

                index = 1;
                for(position of unwaitingPositions) {
                    elementData = {
                        attrs: {
                            id: 'position-' + position.pos_id,
                        },
                        props: {
                            index: index,
                            position: position,
                            editable: this.editable,
                            isShowColAction: this.isShowColAction,
                            hasDiscount: order.hasDiscount,
                            units: this.units,
                            urlSearch: '/api/manager/order/catalog-search?id=' + order.id,
                        },
                        on: {
                            startEdit: (e) => this.$emit('startEdit', e),
                            cancel: (e) => this.$emit('cancel', e),
                            remove: (e) => this.$emit('remove', e),
                            save: (e) => this.$emit('save', e),
                        },
                    }

                    if (this.editable.indexOf(position.pos_id) !== -1) {
                        element = h(PositionUpdate, elementData)
                    } else {
                        element = h(PositionView, elementData)
                    }

                    elements.push(element);
                    index++;
                }

                if (this.isShowColAction) {
                    elements.push(h(
                        PositionInsert,
                        {
                            props: {
                                editable: this.editable,
                                addedPositions: addedPositions,
                                urlSearch: '/api/manager/order/catalog-search?id=' + order.id,
                                units: this.units,
                            },
                            on: {
                                save: (e) => this.$emit('insertPosition', e),
                                chooseAdded: (e) => this.$emit('chooseAdded', e),
                            }
                        }
                    ));
                }

                if (waitingPositions.length > 0) {
                    elements.push(h(
                        'tr',
                        [
                            h(
                                'td',
                                {
                                    attrs: {
                                        colspan: this.isShowColAction ? 5 : 4,
                                        style: "font-weight: bold; background: #e3e3e3;",
                                    }
                                },
                                [
                                    'Добавить к заказу, если есть в наличии',
                                ]
                            ),
                        ]
                    ));
                }

                for(position of waitingPositions) {
                    elementData = {
                        attrs: {
                            id: 'position-' + position.pos_id,
                        },
                        props: {
                            index: index,
                            position: position,
                            editable: this.editable,
                            isShowColAction: this.isShowColAction,
                            hasDiscount: order.hasDiscount,
                            units: this.units,
                            urlSearch: '/api/manager/order/catalog-search?id=' + order.id,
                        },
                        on: {
                            startEdit: (e) => this.$emit('startEdit', e),
                            cancel: (e) => this.$emit('cancel', e),
                            remove: (e) => this.$emit('remove', e),
                            save: (e) => this.$emit('save', e),
                        },
                    }

                    if (this.editable.indexOf(position.pos_id) !== -1) {
                        element = h(PositionUpdate, elementData)
                    } else {
                        element = h(PositionView, elementData)
                    }

                    elements.push(element);
                    index++;
                }

                if (order.paymentMethod > 0 && order.terminalTax) {
                    let childrens = [
                        h('td'),
                        h(
                            'td',
                            {attrs: {class: 'col-name', colspan: 2}},
                            ['Комиссия за оплату по терминалу']
                        ),
                        h(
                            'td',
                            {attrs: {class: 'col-price'}},
                            [order.terminalTax + ' руб.']
                        ),
                    ];

                    if (this.isShowColAction) {
                        childrens.push(
                            h('td', {attrs: {class: 'col-count'}})
                        )
                    }

                    elements.push(h(
                        'tr',
                        childrens
                    ));
                }

                return h('tbody', elements);
            },
        },
        'position-insert': PositionInsert,
        'v-input-datepicker': InputDatepicker,
        'v-list-dialog': ListDialog,
        'v-price-types-dialog': PriceTypesDialog,
    },
};
</script>