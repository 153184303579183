import Vue from 'vue';

export default Vue.extend({
    data() {
        return {
            id: null,
            type: '',
            price_type: null,
            email: null,
            params: [],
            token: null,
            permissions: {},
            orderSelectedSubtitles: [],
            usersSelectedSubtitles: [],
            filledUserProduct: false,
            restAlwaysAvailable: false,
            lifetimeOss: null,
            showCatalogRest: true,
            isConfirmEmailSendingEnabled: false,
            hasProductLinksErrors: false,
            hasNewOrders: false,
            selectedStorage: null,
            guestSettings: {
                showPrice: false,
            },
            discounts: {
                orderAmountMoreMonth: {
                    enabled: false,
                    has: false,
                    percent: 0,
                    restSum: 0,
                    monthAmount: 0,
                },
                orderAmountMore: {
                    enabled: false,
                    amount: 0,
                },
                getDiscount: {
                    enabled: false,
                },
                selfOrdering: {
                    enabled: false,
                },
            },
        };
    },
    created() {
        let token = window.localStorage.getItem('token');

        if (token) {
            this.token = token;
            this.load().then(() => {
                this.$emit('loaded');

                if (this.isManager || this.isAdmin) {
                    this.updateNewOrdersStatus();

                    if (this.$user.hasPermission('canManageProductLinks')) {
                        this.updateProductLinkErrorsStatus();
                    }
                }
            });
        } else {
            this.guestSettings.showPrice = false;

            /*
            this.$http.get(
                '/api/catalog/guest-settings',
                {params: {manager: this.$bus.referralManager}}
            ).then(response => {
                this.guestSettings.showPrice = response.data.showPrice;
            });
             */
        }

        let user = JSON.parse(window.localStorage.getItem('user'));

        if (user) {
            this.id = user.id;
            this.type = user.type;
            this.price_type = user.price_type;
            this.email = user.email;
            this.params = user.params;

            Vue.prototype.$socket.connect(this.token);
        }

        if (localStorage.orderSelectedSubtitles) {
            this.orderSelectedSubtitles = JSON.parse(localStorage.orderSelectedSubtitles);
        } else if (localStorage.ordersViewParams) {
            this.orderSelectedSubtitles = JSON.parse(localStorage.ordersViewParams);
        } else {
            this.orderSelectedSubtitles = [
                'status',
                'shipping',
                'shippingDate',
                'managerOrganisation',
                'managerAddress'
            ];
        }

        if (localStorage.usersSelectedSubtitles) {
            this.usersSelectedSubtitles = JSON.parse(localStorage.usersSelectedSubtitles);
        } else {
            this.usersSelectedSubtitles = ['name', 'phone', 'legal_names', 'actual_names'];
        }

        this.$bus.$on('loadProductsLink', this.updateProductLinkErrorsStatus);
    },
    destroyed() {
        this.$bus.$off('loadProductsLink', this.updateProductLinkErrorsStatus);
    },
    socket() {
        return [
            {
                namespace: '/order',
                events: {
                    sended() {
                        this.updateNewOrdersStatus();
                    },
                    created() {
                        this.updateNewOrdersStatus();
                    },
                    updated() {
                        this.updateNewOrdersStatus();
                    },
                    canceled() {
                        this.updateNewOrdersStatus();
                    },
                    completed() {
                        this.updateNewOrdersStatus();
                    },
                    confirm() {
                        this.updateNewOrdersStatus();
                    },
                    startEdit() {
                        this.updateNewOrdersStatus();
                    },
                    endEdit() {
                        this.updateNewOrdersStatus();
                    },
                },
            },
            {
                namespace: '/import',
                events: {
                    completed() {
                        this.updateProductLinkErrorsStatus();
                    },
                },
            },
            {
                namespace: '/product-link',
                events: {
                    updated() {
                        this.updateProductLinkErrorsStatus();
                    },
                },
            },
        ];
    },
    methods: {
        setToken(token) {
            this.token = token;
            window.localStorage.setItem('token', token);
            this.load();
        },
        load() {
            return this.$http.get('/api/user').then(response => {
                this.id = response.data.id;
                this.type = response.data.user_type;
                this.email = response.data.email;
                this.params = response.data.params;
                this.price_type = response.data.price_type;
                this.discounts = response.data.discounts;
                this.filledUserProduct = response.data.filledUserProduct;
                this.restAlwaysAvailable = response.data.rest_always_available;
                this.lifetimeOss = response.data.lifetimeOss;
                this.isConfirmEmailSendingEnabled = response.data.is_confirm_email_sending_enabled;
                this.orderSendMethod = response.data.orderSendMethod;
                this.permissions = response.data.permissions;

                this.setYmUserId();

                let user = {
                    id: this.id,
                    type: this.type,
                    price_type: this.price_type,
                    email: this.email,
                    params: this.params,
                };

                window.localStorage.setItem('user', JSON.stringify(user));
            });
        },
        update() {
            this.load();
        },
        reset() {
            this.id = null;
            this.type = '';
            this.price_type = null;
            this.email = null;
            this.params = [];
            this.token = null;
            this.orderSendMethod = null;
            this.permissions = {};
            this.filledUserProduct = false;
            this.restAlwaysAvailable = false;
            this.lifetimeOss = null;
            this.isConfirmEmailSendingEnabled = false;
            this.discounts = {
                orderAmountMoreMonth: {
                    enabled: false,
                    has: false,
                    percent: 0,
                    restSum: 0,
                    monthAmount: 0,
                },
                orderAmountMore: {
                    enabled: false,
                    amount: 0,
                },
                getDiscount: {
                    enabled: false,
                },
            };

            window.localStorage.removeItem('token');
            window.localStorage.removeItem('user');

            Vue.prototype.$socket.connect(this.token);
        },
        setYmUserId() {
            if (typeof ym == 'undefined') {
                return;
            }

            ym(52291717, 'userParams', { // jshint ignore:line
                UserID: this.id,
            });
        },
        setOrderSelectedSubtitles(orderSelectedSubtitles) {
            this.orderSelectedSubtitles = orderSelectedSubtitles;
            localStorage.ordersViewParams = JSON.stringify(this.orderSelectedSubtitles);
        },
        setUsersSelectedSubtitles(usersSelectedSubtitles) {
            this.usersSelectedSubtitles = usersSelectedSubtitles;
            localStorage.usersSelectedSubtitles = JSON.stringify(this.usersSelectedSubtitles);
        },
        getOrderSubtitles() {
            let subtitles = [
                {
                    name: 'status',
                    label: 'Юр. лицо',
                    visible: true,
                    content(order) {
                        if (!order.entity || !order.entity.legal_name) {
                            return 'нет данных';
                        }

                        return order.entity.legal_name;
                    },
                },
                {
                    name: 'shipping',
                    label: 'Способ отгрузки / адрес',
                    visible: true,
                    getLabel(order) {
                        if (order.shippingMethod === 'delivery') {
                            return 'Адрес доставки';
                        }

                        if (order.shippingMethod === 'pickup') {
                            return 'Способ отрузки';
                        }
                    },
                    content(order) {
                        if (order.shippingMethod === 'delivery') {
                            if (!order.branch) {
                                return;
                            }
                            return order.branch.stringAddressWithName;
                        }

                        if (order.shippingMethod === 'pickup') {
                            return order.shippingMethodLabel;
                        }
                    },
                },
                {
                    name: 'shippingDate',
                    label: 'Дата отгрузки',
                    visible: true,
                    content(order) {
                        return order.shippingDate;
                    },
                },
                {
                    name: 'managerOrganisation',
                    label: 'Юр. лицо поставщика',
                    visible: this.isManager,
                    content(order) {
                        return order.managerOrganisation;
                    },
                },
                {
                    name: 'managerAddress',
                    label: 'Адрес поставщика',
                    visible: this.isManager,
                    content(order) {
                        if (!order.managerStorage || !order.managerStorage.address) {
                            return 'Не указано';
                        }

                        return order.managerStorage.address;
                    },
                },
            ];

            return subtitles.filter(f => f.visible);
        },
        hasPermission(permission) {
            if (!this.permissions) {
                return false;
            }

            return this.permissions[permission];
        },
        canSee(name) {
            if (!this.permissions) {
                return false;
            }

            if (!this.permissions.availPages) {
                return false;
            }

            return this.permissions.availPages.includes(name);
        },
        updateProductLinkErrorsStatus() {
            if (!this.isManager && !this.isAdmin) {
                return;
            }

            this.$http.get('/api/manager/product-link/check').then(response => {
                this.hasProductLinksErrors = response.data;
            });
        },
        updateNewOrdersStatus() {
            if (!this.isManager && !this.isAdmin) {
                return;
            }

            this.$http.get('/api/manager/order/has-new').then(response => {
                this.hasNewOrders = response.data;
            });
        },
    },
    computed: {
        isAdmin() {
            return this.id === 1;
        },
        isManager() {
            return this.type === 'manager';
        },
        isCustomer() {
            return this.type === 'customer';
        },
        isWorker() {
            return this.type === 'worker';
        },
        isGuest() {
            return !this.type;
        },
        canSeePrice() {
            return !(this.isWorker && this.permissions && this.permissions.hidePrice);
        },
    },
});