import Index from '~/screen/manager/order/Index';
import CreateOrderIndex from '~/screen/manager/order/CreateOrderUserIndex';
import Order from '~/screen/manager/order/Order';
import OrderCatalog from '~/screen/manager/order/Catalog';
import OrderCheckout from '~/screen/manager/order/Checkout';
import OrderCheckoutBranch from '~/screen/order/CreateBranch';
import OrderCheckoutEntity from '~/screen/order/CreateEntity';
import OrderMerge from '~/screen/manager/order/OrderMerge';

import ProductRoute from '~/routes/ProductRoute';

let meta = {
    requiresLogin: true,
    requiredPermissions: ['manager'],
};

let route = [
    {
        path: 'order',
        name: 'manager-orders',
        component: Index,
        meta: {
            requiresLogin: true,
            requiredPermissions: ['manager'],
        },
        children: [
            {
                path: 'create',
                name: 'manager-create-single-order',
                component: CreateOrderIndex,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
                children: [
                    {
                        path: ':user_id/:order_id',
                        name: 'manager-create-order',
                        component: Order,
                        meta: meta,
                        children: [
                            {
                                path: 'catalog',
                                name: 'manager-create-order-catalog',
                                component: OrderCatalog,
                                params: true,
                                meta: {
                                    requiresLogin: true,
                                },
                                children: [
                                    ProductRoute('manager-create-order-catalog'),
                                ]
                            },
                            {
                                path: 'checkout',
                                name: 'manager-create-order-checkout',
                                component: OrderCheckout,
                                params: true,
                                meta: {
                                    requiresLogin: true,
                                },
                                children: [
                                    {
                                        path: 'shipping-addresses-create',
                                        name: 'manager-create-order-checkout-create-branch',
                                        component: OrderCheckoutBranch,
                                        params: true,
                                        meta: {
                                            requiresLogin: true,
                                        },
                                        children: [
                                            {
                                                path: 'entity',
                                                name: 'manager-create-order-checkout-create-entity',
                                                component: OrderCheckoutEntity,
                                                meta: {
                                                    requiresLogin: true,
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                            ProductRoute('manager-create-order'),
                        ]
                    },
                ],
            },
            {
                path: ':order_id',
                name: 'manager-order',
                component: Order,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
                children: [
                    {
                        path: 'merge',
                        name: 'manager-order-merge',
                        component: OrderMerge,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                    },
                    ProductRoute('manager-order-position', '/api/manager/orders/$order/$productId'),
                ]
            },
        ]
    },
];

export default route;