import ProductView from '~/screen/catalog/View';
import ProductUpdate from '~/screen/manager/product/Update';
import ProductSpecial from '~/screen/manager/product/Special';
import ProductMergeReplacements from '~/screen/manager/product/MergeReplacements';
import ProductMergeConfirm from '~/screen/manager/product/MergeConfirm';
import ProductUsers from '~/screen/manager/product/Users';
import ProductUsersReplace from '~/screen/manager/product/Replace';
import ProductAvailabilityBalance from '~/screen/manager/product/ProductAvailabilityBalance';

export default function (basename, catalogViewEntrypoint) {
    return {
        path: ':prd_id',
        name: basename + '-product-view',
        component: ProductView,
        meta: {
            requiresLogin: true,
            catalogViewEntrypoint: catalogViewEntrypoint,
        },
        children: [
            {
                path: 'update',
                name: basename + '-product-update',
                component: ProductUpdate,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
            },
            {
                path: 'special',
                name: basename + '-product-special',
                component: ProductSpecial,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
            },
            {
                path: 'merge',
                name: basename + '-product-merge-replacements',
                component: ProductMergeReplacements,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
                children: [
                    {
                        path: ':choosed',
                        name: basename + '-product-merge-confirm',
                        component: ProductMergeConfirm,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                    },
                ]
            },
            {
                path: 'users',
                name: basename + '-product-users',
                component: ProductUsers,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
                children: [
                    {
                        path: 'replace',
                        name: basename + '-product-users-replace',
                        component: ProductUsersReplace,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                    },
                ],
            },
            {
                path: 'availability-balance',
                name: basename + '-availability-balance',
                component: ProductAvailabilityBalance,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
            },
        ],
    };
}