var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { directives: [{ name: "frag", rawName: "v-frag" }] }, [
    _vm.$window.isDesktop
      ? _c("tr", { staticClass: "manager-order-position-update" }, [
          _c(
            "td",
            { on: { click: _vm.save } },
            [
              _c(
                "v-ons-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.hasModel,
                      expression: "hasModel"
                    }
                  ]
                },
                [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "td",
            { staticClass: "col-name" },
            [
              _c("input-proposal", {
                ref: "inputProposal",
                attrs: {
                  scrollPageOnFocus: true,
                  disableScrollPage: true,
                  disabled: _vm.editable.length > 0,
                  urlSearch: _vm.urlSearch,
                  addedPositions: _vm.addedPositions,
                  showRest: true
                },
                on: { choose: _vm.chooseProposal }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("td", { staticClass: "col-count" }, [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  gap: "10px"
                }
              },
              [
                _c("div", { staticClass: "count-wrap" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.hasModel,
                          expression: "hasModel"
                        }
                      ],
                      staticClass: "wrap"
                    },
                    [
                      _c("div", { staticClass: "col col-button" }, [
                        _c(
                          "div",
                          { staticClass: "btn", on: { click: _vm.decCount } },
                          [_vm._v("-")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col col-input" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.count,
                              expression: "model.count"
                            }
                          ],
                          ref: "inputCount",
                          staticClass: "input-count without-arrow",
                          attrs: { type: "number" },
                          domProps: { value: _vm.model.count },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.model, "count", $event.target.value)
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col col-button" }, [
                        _c(
                          "div",
                          { staticClass: "btn", on: { click: _vm.incCount } },
                          [_vm._v("+")]
                        )
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                !_vm.model.product && _vm.hasModel
                  ? _c(
                      "v-ons-select",
                      {
                        staticStyle: { width: "50px" },
                        model: {
                          value: _vm.model.unit,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "unit", $$v)
                          },
                          expression: "model.unit"
                        }
                      },
                      _vm._l(_vm.units, function(unit) {
                        return _c("option", { domProps: { value: unit.id } }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(unit.name) +
                              "\n                    "
                          )
                        ])
                      }),
                      0
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("td", { staticClass: "col-price" }, [
            _c("input", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasModel,
                  expression: "hasModel"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model.price,
                  expression: "model.price"
                }
              ],
              staticClass: "input-price without-arrow",
              attrs: { type: "number" },
              domProps: { value: _vm.model.price },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.model, "price", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "td",
            {
              staticClass: "col-action",
              on: {
                click: function($event) {
                  return _vm.$emit("startEdit", _vm.model.pos_id)
                }
              }
            },
            [
              _c(
                "v-ons-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.hasModel,
                      expression: "hasModel"
                    }
                  ],
                  on: { click: _vm.cancel }
                },
                [_c("v-ons-icon", { attrs: { icon: "md-block" } })],
                1
              )
            ],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.$window.isDesktop
      ? _c("tr", { staticClass: "manager-order-position-update" }, [
          _c(
            "td",
            { staticClass: "col-name", attrs: { colspan: "5" } },
            [
              _c("input-proposal", {
                ref: "inputProposal",
                attrs: {
                  scrollPageOnFocus: true,
                  disableScrollPage: true,
                  disabled: _vm.editable.length > 0,
                  urlSearch: _vm.urlSearch,
                  addedPositions: _vm.addedPositions,
                  showRest: true
                },
                on: { choose: _vm.chooseProposal }
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.$window.isDesktop && _vm.hasModel
      ? _c("tr", { staticClass: "manager-order-position-update mobile" }, [
          _c("td", { attrs: { colspan: "5" } }, [
            _c("div", { staticClass: "wrap" }, [
              _c(
                "div",
                { staticClass: "col xl-2-6 text-center" },
                [
                  _c(
                    "v-ons-button",
                    { on: { click: _vm.save } },
                    [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-ons-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      on: { click: _vm.cancel }
                    },
                    [_c("v-ons-icon", { attrs: { icon: "md-block" } })],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col xl-2-6" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center",
                      gap: "10px"
                    }
                  },
                  [
                    _c("div", { staticClass: "count-wrap" }, [
                      _c("div", { staticClass: "wrap" }, [
                        _c("div", { staticClass: "col col-button" }, [
                          _c(
                            "div",
                            { staticClass: "btn", on: { click: _vm.decCount } },
                            [_vm._v("-")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col col-input" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.count,
                                expression: "model.count"
                              }
                            ],
                            ref: "inputCount",
                            staticClass: "input-count without-arrow",
                            attrs: { type: "number" },
                            domProps: { value: _vm.model.count },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model,
                                  "count",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col col-button" }, [
                          _c(
                            "div",
                            { staticClass: "btn", on: { click: _vm.incCount } },
                            [_vm._v("+")]
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    !_vm.model.product && _vm.hasModel
                      ? _c(
                          "v-ons-select",
                          {
                            staticStyle: { width: "50px" },
                            model: {
                              value: _vm.model.unit,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "unit", $$v)
                              },
                              expression: "model.unit"
                            }
                          },
                          _vm._l(_vm.units, function(unit) {
                            return _c(
                              "option",
                              { domProps: { value: unit.id } },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(unit.name) +
                                    "\n                            "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col xl-2-6" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.price,
                      expression: "model.price"
                    }
                  ],
                  staticClass: "input-price without-arrow",
                  attrs: { type: "number" },
                  domProps: { value: _vm.model.price },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.model, "price", $event.target.value)
                    }
                  }
                })
              ])
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7cff19df", { render: render, staticRenderFns: staticRenderFns })
  }
}