<template>
    <v-ons-page class="product-view">
        <v-ons-toolbar>
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center">{{ product.category }}</div>

            <div class="right" v-show="!loading && product.id">
                <v-ons-toolbar-button title="Поделиться" @click="share">
                    <v-ons-icon icon="md-share"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button
                    v-if="shouldShowUpdateButton"
                    @click="$router.push($router.currentRoute.path + '/update')
                ">
                    <v-ons-icon icon="md-edit" title="Редактировать карточку товара"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button
                    v-if="$user.isCustomer"
                    v-show="shouldShowAddFavorButton"
                    title='Добавить в "Мои товары"'
                    @click="addFavor"
                >
                    <v-ons-icon icon="md-star"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button
                    v-if="$user.isCustomer"
                    v-show="shouldShowDeleteFavorButton"
                    title='Удалить из "Мои товары"'
                    @click="deleteFavor"
                >
                    <v-ons-icon style="color: #ffe000" icon="md-star"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button
                    v-if="$user.isCustomer"
                    v-show="shouldShowAddCartButton"
                    title="Добавить в заказ"
                    @click="addCart"
                >
                    <v-ons-icon icon="md-shopping-cart"></v-ons-icon>
                </v-ons-toolbar-button>

                <toolbar-popover-menu
                    popover-class="stick-right"
                    v-show="shouldShowAddFavorButton || shouldShowDeleteFavorButton || shouldShowAddCartButton || $user.isManager"
                >
                    <toolbar-popover-menu-item
                        text="Поделиться"
                        icon="md-share"
                        @click="share"
                    />

                    <toolbar-popover-menu-item
                        v-if="$user.isCustomer"
                        v-show="shouldShowAddFavorButton"
                        text='Добавить в "Мои товары"'
                        icon="md-star"
                        @click="addFavor"
                    />

                    <toolbar-popover-menu-item
                        v-if="$user.isCustomer"
                        v-show="shouldShowDeleteFavorButton"
                        text='Удалить из "Мои товары"'
                        icon="md-star"
                        @click="deleteFavor"
                    />

                    <toolbar-popover-menu-item
                        v-if="$user.isCustomer"
                        v-show="shouldShowAddCartButton"
                        text="Добавить в заказ"
                        icon="md-shopping-cart"
                        @click="addCart"
                    />

                    <toolbar-popover-menu-item
                        v-if="$user.isManager"
                        text="Специальный товар"
                        icon="md-flash"
                        @click="$router.push($router.currentRoute.path + '/special')"
                    ></toolbar-popover-menu-item>

                    <toolbar-popover-menu-item
                        v-if="$user.isManager"
                        text="Скопировать параметры"
                        icon="md-link"
                        @click="$router.push($router.currentRoute.path + '/merge')"
                    ></toolbar-popover-menu-item>

                    <toolbar-popover-menu-item
                        v-if="shouldShowProductLinksButton"
                        :style="!availProductLinksButton ? 'color: #b4b4b4;' : ''"
                        :disabled="!availProductLinksButton"
                        :text="availProductLinksButton ? 'Связи товара' : 'Нет связей товара'"
                        icon="md-arrow-merge"
                        @click="$router.push($router.currentRoute.path + '/users')"
                    ></toolbar-popover-menu-item>

                    <toolbar-popover-menu-item
                        v-if="shouldShowProductBalanceButton"
                        text="Наличие и остаток"
                        icon="md-arrow-merge"
                        @click="$router.push($router.currentRoute.path + '/availability-balance')"
                    ></toolbar-popover-menu-item>
                </toolbar-popover-menu>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}">
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <div v-if="!failed">
                <v-product-card v-if="!loading" :product="product"/>
            </div>

            <div v-if="failed" class="page-failed" :style="{height: $window.height - 56 + 'px'}">
                <div class="message">{{ errorMessage }}</div>
            </div>

            <div class="sushiroduct-logo">
                <div class="wrap xl-gutter-16">
                    <div class="col col-logo">
                        <img src="/img/logo-icon-180x180.png" alt="Закуп!">
                    </div>
                    <div class="col col-text">
                        <div class="wrap-text">
                            prod-zakaz.ru
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <select-add-method-modal v-if="!$user.isGuest" ref="selectAddMethodModal"/>
    </v-ons-page>
</template>

<script>

import SelectAddMethodModal from '../../component/SelectAddMethodModal'
import copy from "copy-text-to-clipboard";

export default {
    metaInfo() {
        // don't forget to update in php
        let title = 'Прайс поставщика: ' + this.product.name;
        let description = 'Продукты оптом для ресторана, суши бара, пиццерии, фаст фуда. Раздел каталога: ' + this.product.category;
        let keywords = this.product.words;

        if (!this.product.name) {
            return;
        }

        return {
            title: title,
            meta: [
                {
                    name: 'description',
                    content: description,
                    vmid: 'description',
                },
                {
                    name: 'keywords',
                    content: keywords,
                    vmid: 'keywords',
                },
            ],
        }
    },
    data() {
        return {
            product: {
                specialPrice: {
                    isLow: false,
                }
            },
            loading: false,
            failed: false,
            errorMessage: 'Произошла ошибка',
            catalogViewEntrypoint: '/api/catalog/view',
            userProductAddEntrypoint: '/api/user-products/add-products',
            userProductDeleteEntrypoint: '/api/user-products/delete-products',
        }
    },
    created() {
        if (this.$route.meta) {
            if (this.$route.meta.catalogViewEntrypoint) {
                let catalogViewEntrypoint = this.$route.meta.catalogViewEntrypoint;
                catalogViewEntrypoint = catalogViewEntrypoint.replace('$userId', this.$route.params.user_id)
                catalogViewEntrypoint = catalogViewEntrypoint.replace('$productId', this.$route.params.prd_id)
                catalogViewEntrypoint = catalogViewEntrypoint.replace('$order', this.$route.params.order_id)
                this.catalogViewEntrypoint = catalogViewEntrypoint;
            }

            if (this.$route.meta.userProductAddEntrypoint) {
                let userProductAddEntrypoint = this.$route.meta.userProductAddEntrypoint;
                this.userProductAddEntrypoint = userProductAddEntrypoint.replace('$userId', this.$route.params.user_id)
            }

            if (this.$route.meta.userProductDeleteEntrypoint) {
                let userProductDeleteEntrypoint = this.$route.meta.userProductDeleteEntrypoint;
                this.userProductDeleteEntrypoint = userProductDeleteEntrypoint.replace('$userId', this.$route.params.user_id)
            }
        }

        this.load();

        this.$bus.$on('catalog-update-product', this.load);
    },
    methods: {
        load() {
            this.loading = true;
            this.$http.get(
                this.catalogViewEntrypoint,
                {
                    params: {
                        id: this.$route.params.prd_id,
                        storageId: this.$user.selectedStorage,
                    }
                }
            ).then(response => {
                this.loading = false;

                if (this.$user.isManager && !this.$user.permissions.canDownloadPriceAndPhoto) {
                    this.$ons.notification.alert({title: '', message: 'Просмотр карточки товара доступен по подписке'});
                    return
                }

                this.product = response.data;

                if (this.$user.priceType && this.product.prices) {
                    const productPrice = this.product.prices.find(product => parseInt(this.$user.priceType) === product.typeId)
                    if (productPrice) {
                        this.product.price = productPrice.price
                    }
                }
            }, (response) => {
                if (response.status === 404) {
                    this.errorMessage = 'Товар не найден'
                }
                this.failed = true;
                this.loading = false;
            });
        },
        addFavor() {
            this.product.isInMyProducts = true;
            this.$http.post(this.userProductAddEntrypoint, {positions: [this.product.id]}).then(() => {
                this.$bus.$emit('product-update-favor-' + this.product.id, true);
            }, () => {
                this.product.isInMyProducts = false;
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
        deleteFavor() {
            this.product.isInMyProducts = false;
            this.$http.post(this.userProductDeleteEntrypoint, {products: [this.product.id]}).then(() => {
                this.$bus.$emit('product-update-favor-' + this.product.id, false);
            }, () => {
                this.product.isInMyProducts = true;
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
        addCart() {
            let positions = [{
                prd_id: this.product.id,
                count: this.product.mult,
            }];

            this.$refs.selectAddMethodModal.add(positions);
        },
        share() {
            let url = 'https://prod-zakaz.ru/catalog/' + this.product.id;

            if (!this.$window.isDesktop && navigator.share) {
                let params = {
                    title: this.product.title,
                    url: url,
                }

                navigator.share(params);
            } else {
                copy(url);
                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Ссылка на товар скопирована',
                    buttonLabel: 'OK',
                });
            }
        },
    },
    computed: {
        availProductLinksButton() {
            if (!this.product) {
                return false;
            }

            return this.product.countProductLinks;
        },
        shouldShowAddFavorButton() {
            if (this.$user.isGuest) {
                return false;
            }

            if (!this.product) {
                return false;
            }

            if (this.product.is_deleted && !this.product.is_avail) {
                return false;
            }

            if (this.$user.isWorker) {
                return false;
            }

            return !this.product.isInMyProducts;
        },
        shouldShowDeleteFavorButton() {
            if (this.$user.isGuest) {
                return false;
            }

            if (!this.product) {
                return false;
            }

            if (this.product.is_deleted && !this.product.is_avail) {
                return false;
            }

            if (this.$user.isWorker) {
                return false;
            }

            return this.product.isInMyProducts;
        },
        shouldShowAddCartButton() {
            if (this.$user.isGuest) {
                return false;
            }

            if (this.$route.meta.hiddenButtons && this.$route.meta.hiddenButtons.includes('addCartButton')) {
                return false;
            }

            if (!this.product) {
                return false;
            }

            return !(this.product.is_deleted && !this.product.is_avail);
        },
        shouldShowProductLinksButton() {
            if (!this.$user.isManager) {
                return false;
            }

            return !(this.$route.meta.hiddenButtons && this.$route.meta.hiddenButtons.includes('productLinksButton'));
        },
        shouldShowProductBalanceButton() {
            if (!this.$user.isManager || !this.$user.hasPermission('canManageProductLinks')) {
                return false;
            }

            return !(this.$route.meta.hiddenButtons && this.$route.meta.hiddenButtons.includes('productBalanceButton'));
        },
        shouldShowUpdateButton() {
            const isRightRoute = ['catalog-product-view', 'report-no-photos-products-product-view', 'manager-order-position-product-view'].includes(this.$route.name)
            return isRightRoute && this.$user.isManager && this.$user.permissions.canUpdateProduct
        }
    },
    components: {
        SelectAddMethodModal,
    },
};
</script>