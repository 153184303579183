var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    { staticClass: "management-shipping-payment-methods-page" },
    [
      _c("v-ons-toolbar", [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("v-ons-back-button", {
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v("Типы цен, скидки, способы отгрузки")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              { on: { click: _vm.save } },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "ons-list",
        [
          _c("div", { staticClass: "wrap" }, [
            _c("div", { staticClass: "col xl-1-2" }, [
              _c("div", { staticClass: "list-header list-header--material" }, [
                _vm._v(
                  "\n                    Типы клиентов/цен:\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col xl-1-2" }, [
              _c(
                "div",
                {
                  staticClass: "list-header list-header--material",
                  staticStyle: { "text-align": "right" }
                },
                [
                  _vm._v("\n                    % наценки"),
                  _c("br"),
                  _vm._v("от базового типа цен\n                ")
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _vm._l(_vm.priceTypes, function(type) {
            return _c(
              "ons-list-item",
              { key: type.id, attrs: { modifier: "longdivider" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "left",
                    staticStyle: { "min-width": "30px" },
                    on: {
                      click: function($event) {
                        return _vm.renamePriceType(type)
                      }
                    }
                  },
                  [_c("v-ons-icon", { attrs: { icon: "md-edit" } })],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "center" }, [
                  _c("span", { staticClass: "list-item__title" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(type.name) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  type.id === 1
                    ? _c("span", { staticClass: "list-item__subtitle" }, [
                        _vm._v(
                          "\n                    Этот тип цен отображается клиентам сразу после регистрации."
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    Рекомендуется сделать завышение, а тип цен менять в процессе переговоров.\n                "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "right" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: type.markup,
                        expression: "type.markup"
                      }
                    ],
                    staticClass: "without-arrow",
                    staticStyle: { width: "50px", "text-align": "center" },
                    attrs: { type: "number" },
                    domProps: { value: type.markup },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(type, "markup", $event.target.value)
                      }
                    }
                  })
                ])
              ]
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "ons-list",
        [
          _c("ons-list-item", { attrs: { modifier: "longdivider" } }, [
            _c("div", { staticClass: "center" }, [
              _c("b", [_vm._v("Комиссия при оплате по терминалу (%)")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "right" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.terminalTax,
                    expression: "terminalTax"
                  }
                ],
                staticClass: "without-arrow",
                staticStyle: { "text-align": "center", width: "50px" },
                attrs: { type: "number", placeholder: "0" },
                domProps: { value: _vm.terminalTax },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.terminalTax = $event.target.value
                  }
                }
              })
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "ons-list",
        [
          _c("ons-list-item", { attrs: { modifier: "longdivider" } }, [
            _c("div", { staticClass: "center" }, [
              _vm._v(
                "\n                Отображать цены не авторизованным посетителям:\n            "
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c(
                  "v-ons-select",
                  {
                    model: {
                      value: _vm.guestPriceType,
                      callback: function($$v) {
                        _vm.guestPriceType = $$v
                      },
                      expression: "guestPriceType"
                    }
                  },
                  [
                    _c("option", { domProps: { value: "" } }, [
                      _vm._v("Не отображать")
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.priceTypes, function(type) {
                      return _c(
                        "option",
                        { key: type.id, domProps: { value: type.id } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(type.name) +
                              "\n                    "
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "list-header--material" }, [
        _vm._v("Способ предоставления скидки клиенту:")
      ]),
      _vm._v(" "),
      _c("discount-section", {
        attrs: {
          title: _vm.discounts.get_discount.name,
          forPriceType: _vm.discounts.get_discount.forPriceType,
          gotoPriceType: _vm.discounts.get_discount.gotoPriceType,
          isEnabled: _vm.discounts.get_discount.isEnabled,
          priceTypes: _vm.priceTypes
        },
        on: {
          "update:forPriceType": function($event) {
            return _vm.$set(_vm.discounts.get_discount, "forPriceType", $event)
          },
          "update:for-price-type": function($event) {
            return _vm.$set(_vm.discounts.get_discount, "forPriceType", $event)
          },
          "update:gotoPriceType": function($event) {
            return _vm.$set(_vm.discounts.get_discount, "gotoPriceType", $event)
          },
          "update:goto-price-type": function($event) {
            return _vm.$set(_vm.discounts.get_discount, "gotoPriceType", $event)
          },
          "update:isEnabled": function($event) {
            return _vm.$set(_vm.discounts.get_discount, "isEnabled", $event)
          },
          "update:is-enabled": function($event) {
            return _vm.$set(_vm.discounts.get_discount, "isEnabled", $event)
          }
        }
      }),
      _vm._v(" "),
      _c("discount-section", {
        attrs: {
          title: _vm.discounts.order_amount_more.name,
          forPriceType: _vm.discounts.order_amount_more.forPriceType,
          gotoPriceType: _vm.discounts.order_amount_more.gotoPriceType,
          isEnabled: _vm.discounts.order_amount_more.isEnabled,
          amount: _vm.discounts.order_amount_more.amount,
          hasAmount: true,
          priceTypes: _vm.priceTypes
        },
        on: {
          "update:forPriceType": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more,
              "forPriceType",
              $event
            )
          },
          "update:for-price-type": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more,
              "forPriceType",
              $event
            )
          },
          "update:gotoPriceType": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more,
              "gotoPriceType",
              $event
            )
          },
          "update:goto-price-type": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more,
              "gotoPriceType",
              $event
            )
          },
          "update:isEnabled": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more,
              "isEnabled",
              $event
            )
          },
          "update:is-enabled": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more,
              "isEnabled",
              $event
            )
          },
          "update:amount": function($event) {
            return _vm.$set(_vm.discounts.order_amount_more, "amount", $event)
          }
        }
      }),
      _vm._v(" "),
      _c("discount-section", {
        attrs: {
          title: _vm.discounts.order_amount_more_month.name,
          forPriceType: _vm.discounts.order_amount_more_month.forPriceType,
          gotoPriceType: _vm.discounts.order_amount_more_month.gotoPriceType,
          isEnabled: _vm.discounts.order_amount_more_month.isEnabled,
          amount: _vm.discounts.order_amount_more_month.amount,
          hasAmount: true,
          priceTypes: _vm.priceTypes
        },
        on: {
          "update:forPriceType": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more_month,
              "forPriceType",
              $event
            )
          },
          "update:for-price-type": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more_month,
              "forPriceType",
              $event
            )
          },
          "update:gotoPriceType": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more_month,
              "gotoPriceType",
              $event
            )
          },
          "update:goto-price-type": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more_month,
              "gotoPriceType",
              $event
            )
          },
          "update:isEnabled": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more_month,
              "isEnabled",
              $event
            )
          },
          "update:is-enabled": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more_month,
              "isEnabled",
              $event
            )
          },
          "update:amount": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more_month,
              "amount",
              $event
            )
          }
        }
      }),
      _vm._v(" "),
      _c("discount-section", {
        attrs: {
          title: _vm.discounts.self_ordering.name,
          forPriceType: _vm.discounts.self_ordering.forPriceType,
          gotoPriceType: _vm.discounts.self_ordering.gotoPriceType,
          isEnabled: _vm.discounts.self_ordering.isEnabled,
          amount: _vm.discounts.self_ordering.amount,
          hasAmount: false,
          priceTypes: _vm.priceTypes
        },
        on: {
          "update:forPriceType": function($event) {
            return _vm.$set(_vm.discounts.self_ordering, "forPriceType", $event)
          },
          "update:for-price-type": function($event) {
            return _vm.$set(_vm.discounts.self_ordering, "forPriceType", $event)
          },
          "update:gotoPriceType": function($event) {
            return _vm.$set(
              _vm.discounts.self_ordering,
              "gotoPriceType",
              $event
            )
          },
          "update:goto-price-type": function($event) {
            return _vm.$set(
              _vm.discounts.self_ordering,
              "gotoPriceType",
              $event
            )
          },
          "update:isEnabled": function($event) {
            return _vm.$set(_vm.discounts.self_ordering, "isEnabled", $event)
          },
          "update:is-enabled": function($event) {
            return _vm.$set(_vm.discounts.self_ordering, "isEnabled", $event)
          },
          "update:amount": function($event) {
            return _vm.$set(_vm.discounts.self_ordering, "amount", $event)
          }
        }
      }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("table", { staticClass: "table" }, [
        _c("thead", [
          _c(
            "tr",
            [
              _c("th", [
                _vm._v("Способы отгрузки"),
                _c("br"),
                _vm._v("по типам клиентов/цен")
              ]),
              _vm._v(" "),
              _vm._l(_vm.shippingMethods, function(method) {
                return _c(
                  "th",
                  { key: method.id, staticClass: "text-center" },
                  [_vm._v(_vm._s(method.name))]
                )
              })
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.priceTypes, function(type) {
            return _c(
              "tr",
              { key: type.id },
              [
                _c("td", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(type.name) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _vm._l(_vm.shippingMethods, function(method) {
                  return _c(
                    "td",
                    { key: method.id, staticClass: "text-center" },
                    [
                      _c("v-ons-checkbox", {
                        attrs: { value: type.id },
                        model: {
                          value: method.allow_by_price_type,
                          callback: function($$v) {
                            _vm.$set(method, "allow_by_price_type", $$v)
                          },
                          expression: "method.allow_by_price_type"
                        }
                      })
                    ],
                    1
                  )
                })
              ],
              2
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("table", { staticClass: "table" }, [
        _c("thead", [
          _c(
            "tr",
            [
              _c("th", [
                _vm._v("Способы оплаты"),
                _c("br"),
                _vm._v("по типу отгрузки")
              ]),
              _vm._v(" "),
              _vm._l(_vm.shippingMethods, function(method) {
                return _c(
                  "th",
                  { key: method.id, staticClass: "text-center" },
                  [_vm._v(_vm._s(method.name))]
                )
              })
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.paymentMethods, function(paymentMethod) {
            return _c(
              "tr",
              { key: paymentMethod.id },
              [
                _c("td", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(paymentMethod.name) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _vm._l(_vm.shippingMethods, function(shippingMethod) {
                  return _c(
                    "td",
                    { key: shippingMethod.id, staticClass: "text-center" },
                    [
                      _c("v-ons-checkbox", {
                        attrs: { value: shippingMethod.id },
                        model: {
                          value: paymentMethod.allow_by_shipping_method,
                          callback: function($$v) {
                            _vm.$set(
                              paymentMethod,
                              "allow_by_shipping_method",
                              $$v
                            )
                          },
                          expression: "paymentMethod.allow_by_shipping_method"
                        }
                      })
                    ],
                    1
                  )
                })
              ],
              2
            )
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-15e9571a", { render: render, staticRenderFns: staticRenderFns })
  }
}